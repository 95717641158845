export class ActionPolicy {
  /**
   * @param {Object} actionPolicy FastJson object.
   */
  constructor(actionPolicy = {}) {
    this.action_ID = actionPolicy.attributes.action_ID || null;
    this.action_name = actionPolicy.attributes.action_name || null;
    this.percentage_deduction = actionPolicy.attributes.percentage_deduction || null;
    this.money_deduction = actionPolicy.attributes.money_deduction || null;
    this.days_deduction = actionPolicy.attributes.days_deduction || null;
  }
}
