export class LeaveRequestDetail {
  /**
   * @param {Object} leaverequestdetail FastJson object.
   */
  constructor(leaverequestdetail = {}) {
    this.id = leaverequestdetail.id || null;
    this.RES_ID = leaverequestdetail.attributes.RES_ID || null;
    this.leave_ID = leaverequestdetail.attributes.leave_ID || null;
    this.remaining_per_month = Math.floor(leaverequestdetail.attributes.remaining_per_month) || 0;
    this.remaining_per_year = Math.floor(leaverequestdetail.attributes.remaining_per_year) || 0;
    this.approved_by = leaverequestdetail.attributes.approved_by || null;
    this.month = leaverequestdetail.attributes.month.substring(0, 7) || null;
    this.employee_name = leaverequestdetail.attributes.employee_name || null;
    this.approver_name = leaverequestdetail.attributes.approver_name || null;
    this.leave_name = leaverequestdetail.attributes.leave_name || null;
    this.status = leaverequestdetail.attributes.status ? "Approved" : "Rejected";
    this.manager_name = leaverequestdetail.attributes.manager_name || null; 

  }
}
