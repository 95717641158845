import {List, ListParams} from 'models/list';
import {Target} from 'models/target';

const state = {
  targets: new List(),
};

const getters = {
  targets: (state) => state.targets,
};

const actions = {
  async getTargets({commit}, data) {
    commit('setLoading', true);
    const currentDate = new Date();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = currentDate.getFullYear();
    const formattedDate = `${month}-${year}`;
    data = { ...data, month: formattedDate }; 
    const response = await this.$app.$http.get('/targets', {params: new ListParams(data), date: formattedDate});
    if (response.status === 200) {
      commit('setTargets', response.data);
      commit('setLoading', false);
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  },
};
const mutations = {
  setTargets: (state, data) => {
    state.targets = new List({items: data.map((target) => new Target(target)), count: data.length});
  },
};
export default {
  state,
  getters,
  actions,
  mutations
};