export class Department {
  /**
   * @param {Object} department FastJson object.
   */
  constructor(department = {}) {
    this.id = department.id || null;
    this.department_name = department.attributes.department_name || null;
    this.head_id = department.attributes.head_id || null;
  }
}
