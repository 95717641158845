export class Target {
  /**
   * @param {Object} target FastJson object.
   */
  constructor(target = {}) {
    this.id = target.id || null;
    this.RES_ID = target.RES_ID || null;
    this.name = target.name || null;
    this.team_name = target.team_name || null;
    this.hours_worked = target.worked_hours || null;
    this.target_hours = target.target_hours || null;
    this.remaining_hours = target.remaining_hours || null;
    this.bonus_hours = target.bonus_hours || null;
  }
}