export class DialerData {
  /**
   * @param {Object} dialerData FastJson object.
   */
  constructor(dialerData = {}) {
    this.did = dialerData.id || null;
    this.dialer_ID = dialerData.attributes.dialer_ID || null;
    this.dialer_name = dialerData.attributes.dialer_name || null;
    this.RES_ID = dialerData.attributes.RES_ID || null;
    this.employee_name = dialerData.attributes.employee_name || null;
    this.date = dialerData.attributes.date || null;
    this.hours_worked = this.formatHours(dialerData.attributes.hours_worked);
    this.day_type = dialerData.attributes.day_type || null;
  }

  /**
   * Converts a float number representing hours worked to a string in the format "hours:minutes".
   * @param {string} hoursWorkedString 
   * @returns {string} Formatted hours:minutes string.
   */
  formatHours(hoursWorkedString) {
    if (!hoursWorkedString) return null;

    const hoursFloat = parseFloat(hoursWorkedString);
    const hours = Math.floor(hoursFloat);
    const minutes = Math.round((hoursFloat - hours) * 60);
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  }
}
