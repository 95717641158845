<template>
  <div>
    <h4>{{ name }}</h4>
    <h2 class="pt-2">
      {{ date.format('hh:mm') }}<span class="pl-1 clock-ampm">{{ date.format('A') }}</span>
    </h2>
    <span class="caption font-weight-medium">{{ date.format('ddd, DD MMMM') }}</span>
  </div>
</template>
<script>
import moment from 'moment-timezone';
export default {
  data() {
    return {
      ticker: null,
      date: this.getDateInTimezone()
    };
  },
  props: {
    timezone: String,
    name: String
  },
  methods: {
    getDateInTimezone() {
      return moment.tz(new Date(), this.timezone);
    }
  },
  created() {
    this.ticker = setInterval(() => {
      this.date = this.getDateInTimezone();
    }, 1000);
  },
  destroyed() {
    clearInterval(this.ticker);
  }
};
</script>
<style lang="scss" scoped>
  .clock-ampm {
    font-size: 13px;
  }
  h4, span {
    line-height: 0.5rem !important;
  }

  div {
    justify-content: center;
  }
</style>
