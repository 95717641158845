import Avatar from './Avatar';
import Clock from './Clock';
import Form from './Form';
import Field from './Field';
import Header from './Header';
import Modal from './Modal';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  install(Vue) {
    Vue.component('AppAvatar', Avatar);
    Vue.component('AppField', Field);
    Vue.component('AppForm', Form);
    Vue.component('AppClock', Clock);
    Vue.component('VuePerfectScrollbar', VuePerfectScrollbar);
    Vue.component('AppHeader', Header);
    Vue.component('AppModal', Modal);
  }
};
