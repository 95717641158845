import Vue from 'vue';
import _ from 'lodash';

Vue.mixin({
  methods: {
    getFileUrl(path) {
      return path ? `${process.env.VUE_APP_ACTIVE_STORAGE_URL}${path}` : undefined;
    },
    downloadFile(blob, filename) {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    },
    displayServerErrorsInToaster(errors) {
      if (!_.isNil(errors)) {
        errors.forEach((error) => {
          this.$snotify.error(error.message);
        });
      }
    },
    displayInlineServerErrors(fields, errors) {
      errors.forEach((error) => {
        const fieldKey = _.camelCase(error.field);
        if (fields[fieldKey]) {
          fields[fieldKey].serverErrors.push(error.message);
        }
      });
    },
    clearInlineServerErrors(fields) {
      this._.forEach(fields, (field) => field.serverErrors = []);
    },
    isPermittedRoute(route) {
      if ((this.isAdmin && route.link.requireAdminAuth) ||
        (route.link.requireManagerPermission &&
          ((!route.link.permission &&
          this.currentUser.managerPermissions && this.currentUser.managerPermissions.length !== 0) ||
          this.currentUser.managerPermissions &&
            this.currentUser.managerPermissions.includes(route.link.permission))) ||
        (route.link.currentUserOnly &&
          (this.$route.params.uuid === 'me' || this.$route.params.uuid === this.currentUser.id)) ||
        (!route.link.requireAdminAuth && !route.link.requireAuth)) {
        return true;
      }
      return false;
    },
    successCode(status) {
      return status && `${status}`.startsWith('2');
    },
    durationInSeconds(time) {
      const duration = time.split(':');
      const hours = +duration[0] * 3600;
      const minutes = duration[1] ? (+duration[1] * 60) : 0;
      const seconds = duration[2] ? (+duration[2]) : 0;

      return hours + minutes + seconds;
    }
  }
});
