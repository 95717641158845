import { List, ListParams } from "models/list";
import { Salary } from "models/salary";
import { Sheet } from "models/sheet";

const state = {
  salaries: new List(),
  sheetData: new List()
};

const getters = {
  salaries: state => state.salaries,
  sheetData: state => state.sheetData
};

const actions = {
  async getSalaries({ commit }, data) {
    commit("setLoading", true);
    const response = await this.$app.$http.get("/salaries", { params: new ListParams(data) });
    if (response.status === 200) {
      commit("setSalaries", response.data);
      commit("setLoading", false);
      this.$app.$snotify.success(
        this.$app.$t("messages.success.fetched", {
          entity: this.$app.$tc("models.salary.entity", response.data.total_count)
        })
      );
    } else {
      commit("setLoading", false);
      this.$app.$snotify.error(this.$app.$t("messages.error.internalServerError"));
    }
  },
  async addBonus({ commit }, data) {
    commit("setLoading", true);
    const response = await this.$app.$http.post("/salaries/bonus", data);
    if (response.status === 200) {
      commit("setLoading", false);
      this.$app.$snotify.success(this.$app.$t("messages.success.bonusAdded"));
    } else {
      commit("setLoading", false);
      this.$app.$snotify.error(this.$app.$t("messages.error.created"));
    }
  },
  async addKPI({ commit }, data) {
    commit("setLoading", true);
    const response = await this.$app.$http.post("/salaries/kpi", data);
    if (response.status === 200) {
      commit("setLoading", false);
      this.$app.$snotify.success(this.$app.$t("messages.success.kpiAdded"));
    } else {
      commit("setLoading", false);
      this.$app.$snotify.error(this.$app.$t("messages.error.created"));
    }
  }
};

const mutations = {
  setSalaries(state, data) {
    state.salaries = new List({items: data.items.map((salary) => new Salary(salary)), count: data.total_count});
    state.sheetData = new List({items: data.items.map((salary) => new Sheet(salary)), count: data.total_count});
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};