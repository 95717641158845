export class Lead {
  /**
   * @param {Object} lead FastJson object.
   */
  constructor(lead = {}) {
    this.RES_ID = lead.attributes.RES_ID || null;
    this.month = lead.attributes.month.substring(0, 7) || null;
    this.payable_leads = lead.attributes.payable_leads || null;
    this.employee_name = lead.attributes.employee_name || null;
  }
}