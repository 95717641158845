import Vue from "vue";
import VueRouter from "vue-router";
import _ from "lodash";
import store from "../store";

import sessionRoutes from "./session";
import mainRoutes from "./main";

Vue.use(VueRouter);

const $store = store;
const router = new VueRouter({
  mode: "history",
  routes: [...sessionRoutes, ...mainRoutes],
});

router.beforeResolve((_to, _from, next) => {
  next();
});

router.beforeEach((to, _from, next) => {
  if (
    _.isEmpty($store.getters.accessToken) &&
    _.isNil($store.getters.currentUser.RES_ID)
  ) {
    if (to.name === "loginPage") {
      next();
    } else if(to.name === "welcomePage"){
      next();
    } else
    {
      next({
        path: "/login",
      });
    }
  } else {
    if (to.name === "loginPage" || to.name === "welcomePage") {
      next({
        path: "/main",
      });
    }
    else if(to.meta.requiresAUDITORAuth && to.meta.requiresHRAuth && to.meta.requiresHEADAuth && to.meta.requiresTLAuth){
      if(parseInt(localStorage.type, 10) === 5 || parseInt(localStorage.type, 10) === 2 || parseInt(localStorage.type, 10) === 3 || parseInt(localStorage.type, 10) === 4 || localStorage.head_auditing){
        next();
      }
      else{
        next({
          path: "/main"
        });
      }
    } else if(to.meta.requiresCEOAuth && to.meta.requiresHRAuth && to.meta.requiresHEADAuth){
      if(parseInt(localStorage.type, 10) === 1 || parseInt(localStorage.type, 10) === 2 || parseInt(localStorage.type, 10) === 3){
        next();
      }
      else{
        next({
          path: "/main"
        });
      }
    } else if(to.meta.requiresCEOAuth && to.meta.requiresHEADAuth && to.meta.requiresTLAuth && to.meta.requiresAUDITORAuth){
      if(parseInt(localStorage.type, 10) === 1 || parseInt(localStorage.type, 10) === 3 || parseInt(localStorage.type, 10) === 4 || parseInt(localStorage.type, 10) === 5 || localStorage.head_auditing){
        next();
      }
      else{
        next({
          path: "/main"
        });
      }
    } else if (to.meta.requiresCEOAuth && to.meta.requiresHRAuth && to.meta.requiresAUDITORAuth) {
      if(parseInt(localStorage.type, 10) === 1 || parseInt(localStorage.type, 10) === 2 || parseInt(localStorage.type, 10) === 5 || localStorage.head_auditing){
        next();
      }
      else{
        next({
          path: "/main"
        });
      }
    } else if(to.meta.requiresCEOAuth && to.meta.requiresHRAuth){
      if(parseInt(localStorage.type, 10) === 1 || parseInt(localStorage.type, 10) === 2){
        next();
      }
      else{
        next({
          path: "/main"
        });
      }
    } else if(to.meta.requiresCEOAuth && to.meta.requiresHEADAuth){
      if(parseInt(localStorage.type, 10) === 1 || parseInt(localStorage.type, 10) === 3){
        next();
      }
      else{
        next({
          path: "/main"
        });
      }
    } else if(to.meta.requiresCOLDHEADAuth && to.meta.requiresHRAuth){
      if((parseInt(localStorage.type, 10) === 3 && JSON.parse(localStorage.currentUserData).department_id == 5) || parseInt(localStorage.type, 10) === 2){
        next();
      }
      else{
        next({
          path: "/main"
        });
      }
    } 
    else if(to.meta.requiresCEOAuth){
      if(parseInt(localStorage.type, 10) === 1){
        next();
      }
      else{
        next({
          path: "/main"
        });
    }
   } else if(to.meta.requiresHRAuth){
      if(parseInt(localStorage.type, 10) === 2){
        next();
      }
      else{
        next({
          path: "/main"
        });
      }
    } else if(to.meta.requiresHEADAuth){
      if(parseInt(localStorage.type, 10) === 3){
        next();
      }
      else{
        next({
          path: "/main"
        });
      }
    } else if(to.meta.requiresTLAuth){
      if(parseInt(localStorage.type, 10) === 4 || (parseInt(localStorage.type, 10) === 3 && JSON.parse(localStorage.currentUserData).department_id == 5)){
        next();
      }
      else{
        next({
          path: "/main"
        });
      }
    } else if(to.meta.requiresCOLDHEADAuth){
      if(parseInt(localStorage.type, 10) === 3 && JSON.parse(localStorage.currentUserData).department_id == 5){
        next();
      }
      else{
        next({
          path: "/main"
        });
      }
    }
     else {
      next();
    }
    
  }
});

router.afterEach(() => {
  setTimeout(() => {
    const contentWrapper = document.querySelector("html");
    if (contentWrapper !== null) {
      contentWrapper.scrollTop = 0;
    }
  }, 200);
});

export default router;
