import {List, ListParams} from 'models/list';
import {Lead} from 'models/lead';

const state = {
  leads: new List()
};

const getters = {
  leads: (state) => state.leads
};

const actions = {
  async getLeads({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.get('/leads', {params: new ListParams(data)});
    if (response.status === 200) {
      commit('setLeads', response.data);
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.fetched', {entity: this.$app.$tc('models.lead.entity', response.data.total_count)}));
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  },
  async createLead({commit}, data) {
    commit('setLoading', true);
    data.department_id = localStorage.currentUserData ? JSON.parse(localStorage.currentUserData).department_id : null;
    data.role = localStorage.type
    const response = await this.$app.$http.post('/leads', data);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.created', {entity: 'Lead'}));
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.notQualified'));
    }
  }
};

const mutations = {
  setLeads(state, data) {
    state.leads = new List({items: data.items.map((lead) => new Lead(lead)), count: data.total_count});
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};