export class VacationWorked {
  /**
   * @param {Object} vacationworked FastJson object.
   */
  constructor(vacationworked = {}) {
    this.vid = vacationworked.id || null;
    this.RES_ID = vacationworked.attributes.RES_ID || null;
    this.vac_ID = vacationworked.attributes.vac_ID || null;
    this.hours_worked = this.formatHours(vacationworked.attributes.hours_worked);
    this.date = vacationworked.attributes.date || null;
    this.employee_name = vacationworked.attributes.employee_name || null;
    this.dialer_name = vacationworked.attributes.dialer_name || null;
    this.day_type = vacationworked.attributes.day_type || null;
    this.dialer_ID = vacationworked.attributes.dialer_ID || null;
  }

  /**
   * Converts a float number representing hours worked to a string in the format "hours:minutes".
   * @param {string} hoursWorkedString 
   * @returns {string} Formatted hours:minutes string.
   */
  formatHours(hoursWorkedString) {
    if (!hoursWorkedString) return null;

    const hoursFloat = parseFloat(hoursWorkedString);
    const hours = Math.floor(hoursFloat);
    const minutes = Math.round((hoursFloat - hours) * 60);
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  }
}
