export class Holiday {
  /**
   * @param {Object} holiday FastJson object.
   */
  constructor(holiday = {}) {
    this.id = holiday.id || null;
    this.vac_name = holiday.attributes.vac_name || null;
    this.multiple_of_pay = holiday.attributes.multiple_of_pay || null;
    this.startdate = holiday.attributes.startdate || null;
    this.enddate = holiday.attributes.enddate || null;
  }
}
