export default {
  MANAGE_LEAVE_REQUESTS: {
    title: 'Manage Leave Requests',
    description: 'Request and accept/reject leaves on behalf of other users'
  },
  MANAGE_GOALS: {
    title: 'Manage Goals',
    description: 'Request and accept/reject goals on behalf of other users'
  }
};
