import { List, ListParams } from "models/list";
import { Dialer } from "models/dialer";
import { DialerData } from "models/dialerData";
import { VacationWorked } from "models/vacationWorked";
const state = {
  dialers: new List(),
  workedHours: new List(),
  vacationsWorked: new List()
};

const getters = {
  dialers: (state) => state.dialers,
  workedHours: (state) => state.workedHours,
  vacationsWorked: (state) => state.vacationsWorked
};

const actions = {
  async getDialers({ commit }, data) {
    commit("setLoading", true);
    const response = await this.$app.$http.get("/dialers", { params: new ListParams(data)});
    if (response.status === 200) {
      commit("setDialers", response.data);
      commit("setLoading", false);
    } else {
      commit("setLoading", false);
      this.$app.$snotify.error(this.$app.$t("messages.error.internalServerError"));
    }
  },
  async createDailyHours({ commit }, data) {
    commit("setLoading", true);
    const response = await this.$app.$http.post("/dialer_data", data);
    if (response.status === 200) {
      commit("setLoading", false);
      this.$app.$snotify.success(this.$app.$t("messages.success.hoursLogged"));
    } else {
      commit("setLoading", false);
      this.$app.$snotify.error(this.$app.$t("messages.error.internalServerError"));
    }
  },
  async getWorkedHours({ commit }, data) {
    commit("setLoading", true);
    const response = await this.$app.$http.get("/dialer_data", { params: new ListParams(data)});
    const response_vacationsWorked = await this.$app.$http.get("/vacationworkeds", { params: new ListParams(data)});
    if (response.status === 200) {
      commit("setWorkedHours", response.data);
      commit("setVacationsWorked", response_vacationsWorked.data);
      commit("setLoading", false);
      this.$app.$snotify.success(this.$app.$t('messages.success.fetched', {entity: this.$app.$tc('models.dailyHours.entity', response.data.total_count)}));
    } else {
      commit("setLoading", false);
      this.$app.$snotify.error(this.$app.$t("messages.error.internalServerError"));
    }
  },
  async createVacationHours({ commit }, data) {
    commit("setLoading", true);
    const response = await this.$app.$http.post("/vacationworkeds", data);
    if (response.status === 406) {
      commit("setLoading", false);
      this.$app.$snotify.error(this.$app.$t("messages.error.wrongVacationDate"));
    } else if (response.status === 200) {
      commit("setLoading", false);
      this.$app.$snotify.success(this.$app.$t("messages.success.hoursLogged"));
    } else {
      commit("setLoading", false);
      this.$app.$snotify.error(this.$app.$t("messages.error.internalServerError"));
    }
  },
  async updateDailyHours({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.put(`/dialer_data/${data.id}`, data);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {entity: this.$app.$tc('models.dailyHours.entity', 1)}));
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  },
  async updateVacationHours({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.put(`/vacationworkeds/${data.id}`, data);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {entity: this.$app.$tc('models.dailyHours.entity', 1)}));
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  }
};

const mutations = {
  setDialers(state, data) {
    state.dialers = new List({items: data.items.map((dialer) => new Dialer(dialer)), count: data.total_count});
  },
  setWorkedHours(state, data) {
    state.workedHours = new List({items: data.items.map((dialerData) => new DialerData(dialerData)), count: data.total_count});
  },
  setVacationsWorked(state, data) {
    state.vacationsWorked = new List({items: data.items.map((vacationWorked) => new VacationWorked(vacationWorked)), count: data.total_count});
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};