export default {
  submit: 'Submit',
  login: 'Login',
  setPassword: 'Set Password',
  resetPassword: 'Reset Password',
  register: 'Register',
  invite: 'Invite',
  new: 'New',
  create: 'Create {entity}',
  save: 'Save',
  send: 'Send',
  confirm: 'Confirm',
  cancel: 'Cancel',
  clear: 'Clear',
  update: 'Update',
  reset: 'Reset',
  sendInvitation: 'Send Invitation',
  search: 'Search',
  sync: 'Sync',
  syncAll: 'Sync All',
  delete: 'Delete',
  discard: 'Discard entry',
  start: 'Start',
  stop: 'Stop',
  pause: 'Pause',
  resume: 'Resume',
  give: 'Give {entity}',
  proceed_to_login: 'Proceed to login',
  fire: 'fire'
};
