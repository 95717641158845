import moment from 'moment';

export class Sheet {
  /**
   * @param {Object} sheet FastJson object.
   */
  constructor(sheet = {}) {
    this.payoneer_account = sheet.attributes.employee_payoneer_account || null;
    this.payoneer_balance_to_pay_from = "USD";
    this.amount = sheet.attributes.total_pay || 0;
    this.transaction_description = sheet.attributes.start_date 
      ? moment(sheet.attributes.start_date).format("MMMM") + " Salary"
      : null;
  }
}
