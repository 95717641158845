<template>
  <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="onSubmit">
    <v-row>
      <v-col v-for="field in fields" :key="field.key" :class="field.classes" v-show="!field.hidden">
        <app-field v-bind="field"
                   @input="bindValue($event, field)"
                   @selection-changed="selectionChanged(field)"
                   @date-changed="dateChanged(field)"
                   @time-changed="timeChanged(field)"
                   @file-attached="$emit('file-attached', $event)"
                   @clear-server-errors="clearServerErrors(field)"
                   @text-changed="textChanged(field)"/>
      </v-col>
      <div :class="footerClass">
        <v-btn type="submit" :disabled="!valid" :class="submitButton.classes || 'primary'"
          :block="submitButton.block"
          v-if="showSubmitButton" :icon="submitButton.iconButton">
          <v-icon :left="!submitButton.iconButton" v-if="submitButton.icon">{{ submitButton.icon }}</v-icon>
          <span v-if="!submitButton.iconButton">{{ submitButton.label }}</span>
        </v-btn>
        <v-btn v-if="!disableCancelButton" :class="cancelButton.classes || 'secondary ml-2'"
          :block="cancelButton.block" :color="cancelButton.color"
          @click="reset" :icon="cancelButton.iconButton">
          <v-icon :left="!cancelButton.iconButton" v-if="cancelButton.icon">{{ cancelButton.icon }}</v-icon>
          <span v-if="!cancelButton.iconButton">{{ cancelButton.label }}</span>
        </v-btn>
      </div>
    </v-row>
  </v-form>
</template>

<script>
export default ({
  name: 'Form',
  data() {
    return {
      valid: true,
      resteted: false
    };
  },
  props: {
    fields: {
      type: Object,
      required: true
    },
    footerClass: {
      type: String,
      required: false,
      default: 'col-12 text-right'
    },
    submitButton: {
      type: Object,
      required: false,
      default() {
        return {
          label: this.$t('actions.submit'),
          block: false,
          classes: 'primary'
        };
      }
    },
    cancelButton: {
      type: Object,
      required: false,
      default() {
        return {
          label: this.$t('actions.reset'),
          block: false
        };
      }
    },
    disableCancelButton: {
      type: Boolean,
      required: false,
      default: false
    },
    showSubmitButton: {
      type: Boolean,
      required: false,
      default: true
    },
    iconButton: {
      type: Boolean,
      required: false,
      default: false
    },
    resetForm: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    bindValue(value, field) {
      this.$set(field, 'value', value);
    },
    onSubmit() {
      if (this.$refs.form.validate()) {
        const data = {};
        this._.forEach(this.fields, (field) => {
          data[this._.snakeCase(field.key)] = field.type === 'checkbox' ? !!field.value : field.value;
        });
        this.$emit('submitted', data);
      }
    },
    reset() {
      this.$refs.form.reset();
      this.$emit('canceled');
    },
    selectionChanged(field) {
      if (field.trackable) {
        this.$emit('selection-changed', {key: field.key, value: field.value});
      }
    },
    dateChanged(field) {
      if (field.trackable) {
        this.$emit('date-changed', {key: field.key, value: field.value});
      }
    },
    timeChanged(field) {
      if (field.trackable) {
        this.$emit('time-changed', {key: field.key, value: field.value});
      }
    },
    textChanged(field) {
      if (field.trackable) {
        this.$emit('text-changed', {key: field.key, value: field.value});
      }
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    clearServerErrors(field) {
      field.serverErrors = [];
      this.$emit('clear-server-errors');
    }
  },
  watch: {
    resetForm() {
      if (this.resetForm) {
        this.$refs.form.reset();
      }
    }
  }
});
</script>
