import { List,ListParams } from "models/list";
import { Team } from "models/team";

const state = {
  teams: new List()
};

const getters = {
  teams: state => state.teams
};

const actions = {
  async getTeams({ commit }, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.get('/teams', {params: new ListParams(data)});
    if (response.status === 200) {
      commit('setTeams', response.data);
      commit('setLoading', false);
      // this.$app.$snotify.success(this.$app.$t('messages.success.fetched', {entity: this.$app.$tc('models.team.entity', response.data.total_count)}));
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  }
};
const mutations = {
  setTeams(state, data) {
    state.teams = new List({items: data.items.map((team) => new Team(team)), count: data.total_count});
  }
};
export default {
  state,
  getters,
  actions,
  mutations
}