import {Employee} from 'models/employee';

const state = {
  currentUser: localStorage && localStorage.currentUserData ? new Employee(JSON.parse(localStorage.currentUserData)) : {},
  accessToken: localStorage && localStorage.accessToken ? localStorage.accessToken : '',
  loggedIn: false,
  type: localStorage && localStorage.type ? parseInt(localStorage.type, 10) : ''
};
const getters = {
  currentUser: state => state.currentUser,
  accessToken: state => state.accessToken,
  loggedIn: state => state.loggedIn,
  type: state => state.type,

};
const actions = {
  async login({commit},data){
    commit('setLoading', true);
    const response = await this.$app.$http.post('/login', data);
    if (response.status === 200) {
      commit('setLoggedIn',false);
      commit('setCurrentUserData', new Employee(response.data.user));
      if (response.data.token) {
        commit('setAccessToken', response.data.token);
      }
      this.$app.$router.push('/main');
    } else {
      localStorage.clear();
      commit('setLoggedIn',true);
      commit('setLoading', false);
    }
    return response;
  },
  async logout({commit}) {
    commit('clearUserSession');
    this.$app.$router.push('/');
  },
};
const mutations = {
  setCurrentUserData: (state, userData) => {
    localStorage.setItem('uuid', userData.RES_ID);
    localStorage.setItem('currentUserData', JSON.stringify(userData));
    localStorage.setItem('type', userData.role);
    localStorage.setItem('head_auditing', userData.role == 3 && userData.department_id == 4)
    state.currentUser = userData;
  },
  setAccessToken: (state, accessToken) => {
    localStorage.setItem('accessToken', accessToken);
    state.accessToken = accessToken;
  },
  setLoggedIn(state, value) {
    state.loggedIn = value;
  },
  clearUserSession: (state) => {
    localStorage.removeItem('currentUserData');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('uuid');
    localStorage.removeItem('type');
    localStorage.removeItem('head_auditing');
    state.currentUser = {};
    state.accessToken = '';
    state.loggedIn = false;
    state.type = '';
  },
};
export default {
  state,
  getters,
  mutations,
  actions
};