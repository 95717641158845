export default {
  success: {
    authentication: 'Welcome back!',
    fetched: '{entity} fetched successfully!',
    created: '{entity} was created succesfully!',
    updated: '{entity} was updated succesfully!',
    deleted: '{entity} was deleted succesfully!',
    fired: '{entity} was fired succesfully!',
    invitation: 'Invitation sent successfully!',
    resetPassword: 'Please check you email to reset your password.',
    jiraSync: 'Worklogs are synced successfully!',
    hoursLogged: 'Employee daily hours were logged successfully!',
    bonusAdded: 'Bonus added successfully!',
    kpiAdded: 'KPI added successfully!',
  },
  error: {
    // Server responses
    notQualified: 'You do not have enough permissions to add leads to employees',
    internalServerError: 'An error has occured!',
    forbidden: 'You don\'t have permission to access this resource',
    authentication: 'Invalid credentials',
    created: 'An error has occured while creating!',
    updated: 'An error has occured while updating!',
    domainTaken: 'Domain is already taken',
    deleted: 'An error has occured while deleting!',
    notFound: '{entity} is not found!',
    accountNotExists: 'Account doesn\'t exist',
    googleSync: 'Error occurs while syncing with google API',
    jiraSync: 'Error occurs while syncing with Jira API',
    jiraFetch: 'Error occurs while fetching from jira API',
    insuffecientBalance: 'No enough balance to approve this leave request',
    duplicateRequest: 'This leave has already been requested for this employee',
    invalidPeriod: 'Invalid period in the company to apply for this leave request',
    overlappingRequest: 'Leave request overlaps with an existing leave request',
    consecutiveDays: 'More than 3 days in consecutive leaves',
    // Input Fields errors:
    required: '{entity} is required',
    emailFormat: 'Invalid e-mail format',
    maxCharacter: '{entity} must be less than {length} characters',
    minCharacter: '{entity} must be greater than {length} characters',
    lessThan: '{entity} must be less than {number}',
    lessThanOrEqual: '{entity} must be less or equal than {number}',
    greaterThan: '{entity} must be greater than {number}',
    greaterThanOrEqual: '{entity} must be greater than or equal {number}',
    fileSize: '{entity} size should be less than {size} MB!',
    numeric: "{entity} must be a positive number.",
    unmatchPassword: 'Passwords you entered don\'t match',
    invalidPhoneNumber: 'Invalid Phone Number',
    wrongVacationDate: 'The selected date doesn\'t fall in the vacation period',
    duration: 'End date can\'t be before start date',
    wholeNumber: '{entity} must be a whole number',
    dateContainsSaturdayOrSunday: 'Can\'t apply for leave on Saturday or Sunday, please split in 2 leaves if needed',
  },
  confirmation: {
    delete: 'Are you sure you want to delete {entity} ?',
    changeWorkingHours: 'Changing an employee\'s working hours will affect their leaves and leave requests.',
    duplicateJiraTasks: 'Jira task is already in use. Are you sure you want to save?',
    approveLeave: 'Are you sure you want to approve this leave request?',
    submitLeaveMoreThanDay: 'Are you sure you want to submit this Annual leave request for more than one day (without giving 2 weeks notice) and override the policy?',
    submitShortNoticeLeave: 'Are you sure you want to submit this Short Notice leave request (less than 1 week) and override the policy?',
    rejectLeave: 'Are you sure you want to reject this leave request?',
    fireEmployee: 'Are you sure you want to fire this employee?',
    approveAction: 'Are you sure you want to approve this action?',
    rejectAction: 'Are you sure you want to reject this action?',
    addBonus: 'Are you sure you want to add bonus to this employee?',
    addKPI: 'Are you sure you want to add KPI to this employee?',
    adjustTeams: 'Are you sure you want to change current team for this employee?',
  },
  emptyState: 'Currently you don\'t have any {entity}',
  home: {
    noLeaves: 'No one is off today.',
    goalDeadline: 'Less than 10 days left for this goal!'
  },
  hints: {
    jiraConventions: `You can specify a time unit after a time value "X", such as Xd, Xh or Xm,
to represent days(d), hours(h) and minutes(m), respectively (eg.4d 4h 30m).`
  }
};
