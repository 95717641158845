import {List, ListParams} from 'models/list';
import {Leave} from 'models/leave';

const state = {
  leaves: new List(),
};

const getters = {
  leaves: (state) => state.leaves,
};

const actions = {
  async getLeaves({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.get('/leavetypes', {params: new ListParams(data)});
    if (response.status === 200) {
      commit('setLeaves', response.data);
      commit('setLoading', false);
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  },
};

const mutations = {
  setLeaves: (state, data) => {
    state.leaves = new List({items: data.items.map((leave) => new Leave(leave)), count: data.total_count});
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
