import { List, ListParams} from "models/list";
import { Department } from "models/department";

const state = {
  departments: new List()
};

const getters = {
  departments: state => state.departments
};

const actions = {
  async getDepartments({ commit }, data) {
    commit("setLoading", true);
    const response = await this.$app.$http.get("/departments", {params: new ListParams(data)});
    if (response.status === 200) {
      commit("setDepartments", response.data);
      commit("setLoading", false);
      // this.$app.$snotify.success(
      //   this.$app.$t("messages.success.fetched", { entity: this.$app.$tc("models.department.entity", response.data.total_count)})
      // );
    } else {
      commit("setLoading", false);
      this.$app.$snotify.error(this.$app.$t("messages.error.internalServerError"));
    }
  }
};

const mutations = {
  setDepartments(state, data) {
    state.departments = new List({items: data.items.map((department) => new Department(department)), count: data.total_count});
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};