import {List, ListParams} from 'models/list';
import {LeaveRequest} from 'models/leaveRequest';
import {LeaveRequestDetail} from 'models/leaveRequestDetail';

const state = {
  leaveRequests: new List(),
  leaveRequestDetails: new List(),
  pendingLeaveRequests: new List()
};

const getters = {
  leaveRequests: (state) => state.leaveRequests,
  leaveRequestDetails: (state) => state.leaveRequestDetails,
  pendingLeaveRequests: (state) => state.pendingLeaveRequests
};

const actions = {
  async getLeaveRequests({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.get('/leaverequests', {params: new ListParams(data)});
    if (response.status === 200) {
      commit('setLeaveRequests', response.data);
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.fetched', {entity: this.$app.$tc('models.leaveRequest.entity', response.data.total_count)}));
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  },
  async createLeaveRequest({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.post('/leaverequests', data);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.created', {entity: this.$app.$tc('models.leaveRequest.entity', 1)}));
    } else if ( response.errors.error == "End date cannot be before start date") {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.duration'));
    } else if ( response.status === 406) {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.invalidPeriod'));
    } else if( response.errors.error == "Leave request overlaps with an existing leave request"){
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.overlappingRequest'));
    } else if( response.errors.error == "More than 3 days"){
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.consecutiveDays'));
    } else if (response.status === 422){
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.duplicateRequest'));
    }
    else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  },
  async getLeaveRequestDetails({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.get('leaverequestdetails', {params: new ListParams(data)});
    if (response.status === 200) {
      commit('setLeaveRequestDetails', response.data);
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.fetched', {entity: this.$app.$tc('models.leaveRequestDetail.entity', response.data.total_count)}));
      return response.data;
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  },
  async approveLeaveRequest({commit}, {item,uuid}) {
    commit('setLoading', true);
    const data = {
      leaverequest: {
        RES_ID: item.RES_ID,
        leave_ID: item.leave_ID,
        start_date: item.start_date,
        end_date: item.end_date,
        status: 1,
        submitted_at: item.submitted_at,
        submitted_by: item.submitted_by
      },
      uuid: uuid
    };
    const response = await this.$app.$http.put(`/leaverequests/${item.id}`, data);
      if (response.status === 200) {
        commit('setLoading', false);
        this.$app.$snotify.success(this.$app.$t('messages.success.updated', {entity: this.$app.$tc('models.leaveRequest.entity', 1)}));
      } else if(response.errors[0].code == "You do not have enough leave balance"){
        commit('setLoading', false);
        this.$app.$snotify.error(this.$app.$t('messages.error.insuffecientBalance'));
      } else {
        commit('setLoading', false);
        this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
      }
    },
    async rejectLeaveRequest({commit}, {item,uuid}) {
      commit('setLoading', true);
      const data = {
        leaverequest: {
          RES_ID: item.RES_ID,
          leave_ID: item.leave_ID,
          start_date: item.start_date,
          end_date: item.end_date,
          status: 2,
          submitted_at: item.submitted_at,
          submitted_by: item.submitted_by
        },
        uuid: uuid
      };
      const response = await this.$app.$http.put(`/leaverequests/${item.id}`, data);
      if (response.status === 200) {
        commit('setLoading', false);
        this.$app.$snotify.success(this.$app.$t('messages.success.updated', {entity: this.$app.$tc('models.leaveRequest.entity', 1)}));
      } else {
        commit('setLoading', false);
        this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
      }
    },
    async getPendingLeaveRequests({commit}) {
      commit('setLoading', true);
      const response = await this.$app.$http.get('/leaverequests/pending_leaves', {params: new ListParams()});
      if (response.status === 200) {
        commit('setPendingLeaveRequests', response.data);
        commit('setLoading', false);
      } else {
        commit('setLoading', false);
      }
    }
    
};

const mutations = {
  setLeaveRequests: (state, data) => {
    state.leaveRequests = new List({items: data.items.map((leaveRequest) => new LeaveRequest(leaveRequest)), count: data.total_count});
  },
  setLeaveRequestDetails: (state, data) => {
    state.leaveRequestDetails = new List({items: data.items.map((leaveRequestDetail) => new LeaveRequestDetail(leaveRequestDetail)), count: data.total_count});
  },
  setPendingLeaveRequests: (state, data) => {
    state.pendingLeaveRequests = new List({items: data.items.map((leaveRequest) => new LeaveRequest(leaveRequest)), count: data.total_count});
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};