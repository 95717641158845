// import moment from 'moment';
/**
 * A class that can return the Leave model
 */
export class Leave {
  /** @param {Object} leave FastJson object. */
  constructor(leave = {}) {
    this.id = leave.id || null;
    this.leave_name = leave.attributes.leave_name || null;
    this.allowed_per_month = leave.attributes.allowed_per_month || null;
    this.allowed_per_year = leave.attributes.allowed_per_year || null;
  }
}
