<template>
  <div>
    <v-row justify="start" class="align-center h1 mx-1" no-gutters>
      <v-icon :class="`${this.$vuetify.theme.dark ? 'white--text' : 'grey--text text--darken-2'} mr-3`" size="40">
        {{ title.icon }}
      </v-icon>
      <span :class="`${this.$vuetify.theme.dark ? 'white--text' : 'grey--text text--darken-2'} font-weight-bold`">
        {{ title.text }}
      </span>
    </v-row>
    <v-row align="center">
      <v-col  class="col-12 col-md-6">
        <v-breadcrumbs :items="breadcrumbs" class="mx-1 py-1 px-0" />
      </v-col>
      <v-spacer />
      <v-btn v-if="showButton" @click.stop="handleClick" class="mx-3 mb-4 primary">
        <v-icon small v-if="actionButton.icon" class="mr-3">{{ actionButton.icon }}</v-icon>{{ actionButton.text }}
      </v-btn>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    breadcrumbs: {
      type: Array,
      required: false
    },
    title: {
      type: Object,
      required: true
    },
    actionButton: {
      type: Object,
      required: false,
      default() {
        return {
          text: this.$t('actions.new'),
          icon: 'mdi-plus'
        };
      }
    },
    showButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick() {
      this.$emit('clicked');
    }
  }
};
</script>