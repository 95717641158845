export class Salary {
  /**
   * @param {Object} salary FastJson object.
   */
  constructor(salary = {}) {
    this.RES_ID = salary.attributes.RES_ID || null;
    this.start_date = salary.attributes.start_date || null;
    this.end_date = salary.attributes.end_date || null;
    this.working_days = salary.attributes.working_days || 0;
    this.employee_name = salary.attributes.employee_name || null;
    this.employee_type = salary.attributes.employee_type || null;
    this.employee_manager = salary.attributes.employee_manager || null;
    this.employee_join_date = salary.attributes.employee_join_date || null;
    this.employee_title = salary.attributes.employee_type || null;
    this.employee_payoneer_account = salary.attributes.employee_payoneer_account || null;
    this.week1_hours = salary.attributes.week1_hours || 0;
    this.week1_target = salary.attributes.week1_target || 0;
    this.week2_hours = salary.attributes.week2_hours || 0;
    this.week2_target = salary.attributes.week2_target || 0;
    this.week3_hours = salary.attributes.week3_hours || 0;
    this.week3_target = salary.attributes.week3_target || 0;
    this.week4_hours = salary.attributes.week4_hours || 0;
    this.week4_target = salary.attributes.week4_target || 0;
    this.week5_hours = salary.attributes.week5_hours || 0;
    this.week5_target = salary.attributes.week5_target || 0;
    this.hours_given = salary.attributes.hours_given || 0;
    this.annuals = salary.attributes.annuals || 0;
    this.casuals = salary.attributes.casuals || 0;
    this.sick = salary.attributes.sick || 0;
    this.paid_leave = salary.attributes.paid_leave || 0;
    this.training = salary.attributes.training || 0;
    this.allowance = salary.attributes.allowance || 0;
    this.leads_target = salary.attributes.leads_target || 0;
    this.kpi_tier = salary.attributes.kpi_tier || null;
    this.kpi = salary.attributes.kpi || 0;
    this.bonus = salary.attributes.bonus || 0;
    this.compensation = salary.attributes.compensation || 0;
    this.actions = salary.attributes.actions || 0;
    this.amount = salary.attributes.amount || 0;
    this.lateness = salary.attributes.lateness || 0;
    this.employee_base_salary = salary.attributes.employee_base_salary || 0; // Basic Salary
    this.hourly_rate = salary.attributes.hourly_rate || 0;
    this.target_hours = salary.attributes.target_hours || 0;
    this.target_leads = salary.attributes.target_leads || 0;
    this.hours_pay = salary.attributes.hours_pay || 0;
    this.extras = salary.attributes.extras || 0;
    this.total_pay = salary.attributes.total_pay || 0;
  }
}
