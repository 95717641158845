import _ from 'lodash';
/**
 * A class that can return the List model
 */
export class List {
  /** @param {Object} list object with 2 attributes: items and count (represents the total count) */
  constructor(list = {}) {
    this.items = list.items || [];
    this.count = list.count || 0;
  }
}

/**
 * A class that can return the ListParams model
 */
export class ListParams {
  /** @param {Object} options represents the datatable pagination options */
  constructor(options = {}) {
    this.sort_by = options.sortBy && options.sortBy[0] ? _.snakeCase(options.sortBy[0]) : null;
    this.sort_dir = options.sortDesc && options.sortDesc[0] ? 'desc' : 'asc';
    this.page = options.itemsPerPage !== -1 ? options.page : null;
    this.limit = options.itemsPerPage !== -1 ? options.itemsPerPage : null;
    this.month = options.month ? options.month : null;
    this.role = localStorage.type
    this.department_id = localStorage.currentUserData ? JSON.parse(localStorage.currentUserData).department_id : null;
    this.team_id = localStorage.currentUserData ? JSON.parse(localStorage.currentUserData).team_ID : null;
    this.head_auditing = localStorage.head_auditing 
    this.fields = options.fields ? options.fields.map((field) => _.snakeCase(field)) : [];
    for (const key in options.filterBy) {
      if (Object.prototype.hasOwnProperty.call(options.filterBy, key)) {
        this[_.snakeCase(key)] = options.filterBy[key];
      }
    }
  }
}
