<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-avatar :size="actualSize" color="#ff3131" v-on="tooltip ? on : ''" v-bind="tooltip ? attrs : ''" >
        <span :style="`font-size: ${actualSize/2}px !important`" class="white--text headline">
          {{ alternative }}
        </span>
      </v-avatar>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    alternative: {
      type: String,
      required: true,
      default: '',
      validator: (val) => val.length <= 2
    },
    size: {
      type: String,
      required: false,
      default: 'small',
      validator: (val) => ['xsmall', 'small', 'medium', 'large', 'xlarge'].includes(val.toLowerCase())
    },
    tooltip: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      isError: false
    };
  },
  computed: {
    actualSize() {
      switch (this.size) {
      case 'xsmall':
        return 40;
      case 'small':
        return 50;
      case 'medium':
        return 80;
      case 'large':
        return 120;
      case 'xlarge':
        return 150;
      }
      return 50;
    }
  },
  methods: {
    onImgError() {
      this.isError = true;
    }
  }
};
</script>
