const state = {
  offices: [
    {
      id: 1,
      name: 'Egypt',
      timezone: 'Africa/Cairo'
    },
    {
      id: 2,
      name: 'USA',
      timezone: 'America/New_York'
    }
  ]
}

const getters = {
  offices: (state) => state.offices
}
export default {
  state,
  getters
}