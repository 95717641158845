import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VuetifyConfirm from 'vuetify-confirm';
import Vuelidate from 'vuelidate';
import VueSmoothScroll from 'vue2-smooth-scroll';
import VueSlimScroll from 'vue-slimscroll';
import Snotify, { SnotifyPosition } from 'vue-snotify';
import store from './store';
import SharedComponents from './components/shared';
import i18n from './plugins/i18n';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import './helpers/mixins';
import 'assets/scss/style.scss';

Vue.use(VueLodash, { lodash });
Vue.use(Vuelidate);
Vue.use(VueSmoothScroll);
Vue.use(VueSlimScroll);
Vue.use(Snotify, {
  toast: {
    timeout: 3000,
    position: SnotifyPosition.rightBottom,
  },
});
Vue.use(VuetifyConfirm, {
  vuetify,
  color: 'warning',
  icon: 'warning',
  title: 'Warning',
  width: 350,
  property: '$confirm'
});
Vue.use(SharedComponents);

Vue.config.productionTip = false;

const app = new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
});

store.$app = app;

app.$mount('#app');
