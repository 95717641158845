import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '../translations';
// import store from '../store';

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'en-US',
  fallbackLocale: 'en-US',
  messages
});
