export default [
  {
    path: "/",
    name: "welcomePage",
    component: () => import("../Welcome"),
  },
  {
    path: "/login",
    name: "loginPage",
    component: () => import("../views/sessions/Login"),
  },
];
