import { List, ListParams } from "models/list";
import { ActionPolicy } from "models/actionPolicy";
import { ActionAgainst } from "models/actionAgainst";

const state = {
  actionPolicies: new List(),
  actionsTaken: new List(),
  pendingActions: new List(),
  approvedActions: new List()
};

const getters = {
  actionPolicies: (state) => state.actionPolicies,
  actionsTaken: (state) => state.actionsTaken,
  pendingActions: (state) => state.pendingActions,
  approvedActions: (state) => state.approvedActions
};

const actions = {
  async createActionPolicy({ commit }, data) {
    commit("setLoading", true);
    const response = await this.$app.$http.post("/actionpolicies", data);
    if ( response.status === 200) {
      commit("setLoading", false);
      this.$app.$snotify.success(this.$app.$t("messages.success.created", { entity: this.$app.$tc("models.actionPolicy.entity", 1) }));
    } else {
      commit("setLoading", false);
      this.$app.$snotify.error(this.$app.$t("messages.error.internalServerError"));
    }
  },
  async getActionsTaken({ commit }, data) {
    commit("setLoading", true);
    const response = await this.$app.$http.get("/action_againsts", {params: new ListParams(data)});
    if ( response.status === 200) {
      commit("setActionsTaken", response.data);
      this.$app.$snotify.success(this.$app.$t("messages.success.fetched", { entity: this.$app.$tc("models.actionTaken.entity", response.data.total_count) }));
      commit("setLoading", false);
    } else {
      commit("setLoading", false);
      this.$app.$snotify.error(this.$app.$t("messages.error.internalServerError"));
    }
  },
  async getPendingActions({ commit }, data) {
    commit("setLoading", true);
    const response = await this.$app.$http.get("/action_againsts/pendingActions", {params: new ListParams(data)});
    if ( response.status === 200) {
      commit("setPendingActions", response.data);
      this.$app.$snotify.success(this.$app.$t("messages.success.fetched", { entity: this.$app.$tc("models.actionTaken.entity", response.data.total_count) }));
      commit("setLoading", false);
    } else {
      commit("setLoading", false);
      this.$app.$snotify.error(this.$app.$t("messages.error.internalServerError"));
    }
  },
  async getApprovedActions({ commit }) {
    commit("setLoading", true);
    const response = await this.$app.$http.get("/action_againsts/approvedActions", {params: new ListParams()});
    if ( response.status === 200) {
      commit("setApprovedActions", response.data);
      commit("setLoading", false);
    } else {
      commit("setLoading", false);
    }
  },
  async approveActionRequest({ commit }, { item, uuid }) {
    commit("setLoading", true);
    const data = {
      action_against: {
        id: item.id,
        RES_ID: item.RES_ID,
        action_ID: item.action_ID,
        date: item.date,
        auditor_ID: item.auditor_ID,
        details: item.details,
        status: 1,
        approved_by_ID: uuid
      }
    };
    const response = await this.$app.$http.put(`/action_againsts/${item.id}`, data);
    if ( response.status === 200) {
      commit("setLoading", false);
      this.$app.$snotify.success(this.$app.$t("messages.success.updated", { entity: this.$app.$tc("models.actionTaken.entity", 1) }));
    } else {
      commit("setLoading", false);
      this.$app.$snotify.error(this.$app.$t("messages.error.internalServerError"));
    }
  },
  async rejectActionRequest({ commit }, { item, uuid }) {
    commit("setLoading", true);
    const data = {
      action_against: {
        id: item.id,
        RES_ID: item.RES_ID,
        action_ID: item.action_ID,
        date: item.date,
        auditor_ID: item.auditor_ID,
        details: item.details,
        status: 2,
        approved_by_ID: uuid
      }
    };
    const response = await this.$app.$http.put(`/action_againsts/${item.id}`, data);
    if ( response.status === 200) {
      commit("setLoading", false);
      this.$app.$snotify.success(this.$app.$t("messages.success.updated", { entity: this.$app.$tc("models.actionTaken.entity", 1) }));
    } else {
      commit("setLoading", false);
      this.$app.$snotify.error(this.$app.$t("messages.error.internalServerError"));
    }
  },
  async getActionPolicies({ commit }, data) {
    commit("setLoading", true);
    const response = await this.$app.$http.get("/actionpolicies", {params: new ListParams(data)});
    if ( response.status === 200) {
      commit("setActionPolicies", response.data);
      this.$app.$snotify.success(this.$app.$t("messages.success.fetched", { entity: this.$app.$tc("models.actionPolicy.entity", response.data.total_count) }));
      commit("setLoading", false);
    } else {
      commit("setLoading", false);
      this.$app.$snotify.error(this.$app.$t("messages.error.internalServerError"));
    }
  },
  async createActionAgainst({ commit }, data) {
    commit("setLoading", true);
    const response = await this.$app.$http.post("/action_againsts", data);
    if ( response.status === 200) {
      commit("setLoading", false);
      this.$app.$snotify.success(this.$app.$t("messages.success.created", { entity: this.$app.$tc("models.actionTaken.entity", 1) }));
    } else {
      commit("setLoading", false);
      this.$app.$snotify.error(this.$app.$t("messages.error.internalServerError"));
    }
  }

};
const mutations = {
  setActionsTaken(state, data) {
    state.actionsTaken = new List({items: data.items.map((actionAgainst) => new ActionAgainst(actionAgainst)), count: data.total_count});
  },
  setPendingActions(state, data) {
    state.pendingActions = new List({items: data.items.map((actionAgainst) => new ActionAgainst(actionAgainst)), count: data.total_count});
  },
  setActionPolicies(state, data) {
    state.actionPolicies = new List({items: data.items.map((actionPolicy) => new ActionPolicy(actionPolicy)), count: data.total_count});
  },
  setApprovedActions(state, data) {
    state.approvedActions = new List({items: data.items.map((actionAgainst) => new ActionAgainst(actionAgainst)), count: data.total_count});
  }
}
export default {
  state,
  getters,
  actions,
  mutations
};