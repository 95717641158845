<template>
  <div>
    <template v-if="type === 'text' || type === 'email' || type === 'number'">
      <v-text-field
        v-model="content"
        :label="customLabel"
        :dark="dark"
        :dense="dense"
        :disabled="disabled"
        v-show="!hidden"
        @change="$emit('text-changed', $event)"
        :outlined="outlined"
        :rules="validationRules"
        :type="type"
        :placeholder="customPlaceholder"
        :append-icon="icon"
        :error-messages="serverErrors"
        @input="$emit('clear-server-errors')"
      >
        <template v-slot:append v-if="hint">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">mdi-help-circle-outline</v-icon>
            </template>
            <span style="white-space: pre-wrap">{{ hint }}</span>
          </v-tooltip>
        </template>
      </v-text-field>
    </template>
    <template v-else-if="type === 'textarea'">
      <v-textarea
        v-model="content"
        :label="customLabel"
        :readonly="readonly"
        :dark="dark"
        :dense="dense"
        :disabled="disabled"
        :outlined="outlined"
        :rules="validationRules"
        :type="type"
        :placeholder="customPlaceholder"
        :append-icon="icon"
        rows="3"
        :error-messages="serverErrors"
        @input="$emit('clear-server-errors')"
      />
    </template>
    <template v-else-if="type === 'file'">
      <v-file-input
        v-model="content"
        :label="customLabel"
        :dark="dark"
        :dense="dense"
        :disabled="disabled"
        :outlined="outlined"
        :rules="validationRules"
        :type="type"
        :placeholder="customPlaceholder"
        prepend-icon=""
        :append-icon="icon"
        @click:append="chooseFile"
        :size="size"
        :multiple="multiple"
        :accept="accept"
        :show-size="showSize"
        :clearable="clearable"
        @change="$emit('file-attached', $event)"
        :class="avatarClass"
        ref="fileInput"
        :error-messages="serverErrors"
        @input="$emit('clear-server-errors')"
      />
      <h4 class="file-label mb-5" v-if="pastFile">
        {{ $t("views.constants.fieldPreSelected") }}{{ pastFile }}
      </h4>
    </template>
    <template v-else-if="type === 'phone'">
      <vue-tel-input-vuetify
        v-model="content"
        :label="customLabel"
        :dark="dark"
        :dense="dense"
        :disabled="disabled"
        :outlined="outlined"
        :rules="validationRules"
        :placeholder="customPlaceholder"
        :append-icon="icon"
        @onInput="validatePhone"
        :error="isPhoneInvalid"
        :defaultCountry="defaultCountry"
        :mode="phoneMode"
        :error-messages="serverErrors"
        @input="$emit('clear-server-errors')"
      />
    </template>
    <template v-else-if="type === 'password'">
      <v-text-field
        v-model="content"
        :label="customLabel"
        :dark="dark"
        :dense="dense"
        :disabled="disabled"
        :outlined="outlined"
        :rules="validationRules"
        :type="showPassword ? 'text' : 'password'"
        :placeholder="customPlaceholder"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
        :error-messages="serverErrors"
        @input="$emit('clear-server-errors')"
      />
    </template>
    <template v-else-if="type === 'yearDatePicker'">
      <v-menu
        ref="yearMenu"
        v-model="yearMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            :label="customLabel"
            :dark="dark"
            :dense="dense"
            :disabled="disabled"
            :outlined="outlined"
            append-icon="event"
            v-on:click:append="yearMenu = !yearMenu"
            readonly
            v-bind="attrs"
            v-on="on"
            @click:clear="saveYearDate('')"
            clearable
            :error-messages="serverErrors"
          />
        </template>
        <v-date-picker
          ref="yearPicker"
          v-model="yearPicker"
          :max="new Date().toISOString().substr(0, 10)"
          min="1900-01-01"
          @change="saveYearDate(yearPicker)"
          @input="$emit('clear-server-errors')"
        />
      </v-menu>
    </template>
    <template v-else-if="type === 'datepicker'">
      <v-menu
        v-model="datePickerMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            :label="customLabel"
            :dark="dark"
            :dense="dense"
            :disabled="disabled"
            :outlined="outlined"
            append-icon="mdi-calendar"
            v-on:click:append="datePickerMenu = !datePickerMenu"
            :rules="validationRules"
            readonly
            v-bind="attrs"
            v-on="on"
            clearable
            @click:clear="clearDate"
            :error-messages="serverErrors"
          />
        </template>
        <v-date-picker
          v-model="date"
          @input="saveDate"
          @change="$emit('date-changed')"
          :min="minDate"
          :max="maxDate"
          :allowed-dates="useAllowedDate ? allowedDates : null"
          :week-start="0"
          :first-day-of-week="1"
        />
      </v-menu>
    </template>
    <template v-else-if="type === 'rangeDatepicker'">
      <v-menu
        v-model="datePickerMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dates"
            :label="customLabel"
            :dark="dark"
            :dense="dense"
            :disabled="disabled"
            :outlined="outlined"
            append-icon="event"
            v-on:click:append="datePickerMenu = !datePickerMenu"
            readonly
            v-bind="attrs"
            v-on="on"
            clearable
            :error-messages="serverErrors"
          />
        </template>
        <v-date-picker
          v-model="dates"
          range
          @change="handeRangeDates"
          :min="minDate"
          @input="$emit('clear-server-errors')"
        />
      </v-menu>
    </template>
    <template v-else-if="type == 'radio'">
      <b-form-group>
        <b-form-radio-group
          v-model="content"
          :options="options"
          name="radio-group"
          :stacked="stacked"
        />
      </b-form-group>
    </template>
    <template v-else-if="type == 'checkbox'">
      <v-checkbox
        v-model="content"
        :label="customLabel"
        class="mt-1"
        @change="$emit('selection-changed', $event)"
        :error-messages="serverErrors"
        @input="$emit('clear-server-errors')"
      />
    </template>
    <template v-else-if="type == 'select'">
      <v-select
        v-model="content"
        :items="options"
        :item-text="itemText"
        :item-value="itemValue"
        :dark="dark"
        :outlined="outlined"
        :rules="validationRules"
        :type="type"
        :placeholder="customPlaceholder"
        :chips="chips"
        :multiple="multiple"
        :dense="dense"
        :disabled="disabled"
        :readonly="readonly"
        :label="customLabel"
        :clearable="clearable"
        @change="$emit('selection-changed')"
        :error-messages="serverErrors"
        @input="$emit('clear-server-errors')"
      />
    </template>
    <template v-else-if="type == 'autocomplete'">
      <v-autocomplete
        v-model="content"
        :items="options"
        :item-text="itemText"
        :item-value="itemValue"
        :dark="dark"
        :outlined="outlined"
        :rules="validationRules"
        :type="type"
        :placeholder="customPlaceholder"
        :chips="chips"
        :small-chips="chips"
        :multiple="multiple"
        :dense="dense"
        :disabled="disabled"
        :readonly="readonly"
        :rounded="rounded"
        :clearable="clearable"
        :deletable-chips="deletableChips"
        :label="customLabel"
        :search-input.sync="customSearchKey"
        :loading="isLoading"
        hide-no-data
        :error-messages="serverErrors"
        @input="$emit('clear-server-errors')"
        @change="$emit('text-changed', $event)"
      >
      </v-autocomplete>
    </template>
    <template v-else-if="type === 'timepicker'">
      <v-menu
        v-model="timePickerMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="timeFormatted"
            :label="customLabel"
            :dark="dark"
            :dense="dense"
            :disabled="disabled"
            :outlined="outlined"
            v-on:click:append="timePickerMenu = !timePickerMenu"
            :rules="validationRules"
            append-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            clearable
            @click:clear="saveTime('')"
            :error-messages="serverErrors"
          />
        </template>
        <v-time-picker
          v-model="time"
          ref="timepicker"
          format="24hr"
          scrollable
          @change="saveTime"
          :min="minTime"
          :max="maxTime"
          :allowed-minutes="allowedStepTime"
          @input="$emit('clear-server-errors')"
        />
      </v-menu>
    </template>
    <template v-else-if="type == 'colorpicker'">
      <h3 class="text-center mb-2">{{ label }}</h3>
      <v-color-picker
        v-model="color"
        :disabled="disabled"
        :mode="mode"
        :show-swatches="showSwatches"
        :hide-inputs="hideInputs"
        :hide-canvas="hideCanvas"
        :hide-sliders="true"
        :hide-mode-switch="hideModeSwitch"
        :dot-size="dotSize"
        :swatches-max-height="swatchesMaxHeight"
        :class="classes"
        :flat="flat"
      >
      </v-color-picker>
    </template>
    <template v-else-if="type == 'treeSelect'">
      <treeselect
        v-model="content"
        :options="options"
        :value-consists-of="valueConsistsOf"
        :rules="validationRules"
        :placeholder="customPlaceholder"
        :multiple="multiple"
        :dense="dense"
        :disabled="disabled"
        :readonly="readonly"
        :rounded="rounded"
        :clearable="clearable"
        :append-to-body="true"
      />
    </template>
    <template v-else-if="type == 'slider'">
      <v-slider
        v-model="content"
        :max="maxNumber"
        :label="customLabel"
        :readonly="readonly"
        :dark="dark"
        :dense="dense"
        :disabled="disabled"
        hide-details
        :type="type"
        @change="$emit('text-changed', $event)"
      >
        <template v-slot:append>
          <v-text-field
            v-model="content"
            :outlined="outlined"
            :readonly="readonly"
            :dark="dark"
            :dense="dense"
            :disabled="disabled"
            class="mt-n2 pt-0 slider-text"
            type="number"
            style="width: 85px"
            @change="$emit('text-changed', $event)"
            :suffix="suffix"
            :error-messages="serverErrors"
            @input="$emit('clear-server-errors')"
          />
        </template>
      </v-slider>
    </template>
  </div>
</template>
<script>
import Treeselect from "@riophae/vue-treeselect";
import moment from "moment";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
// import {treeSelectOptions} from 'constants';

export default {
  name: "Field",
  components: { Treeselect },
  data() {
    return {
      date: "",
      time: "",
      timeFormatted: "",
      dates: [],
      yearPicker: null,
      showPassword: false,
      yearMenu: false,
      search: "",
      datePickerMenu: false,
      timePickerMenu: false,
      isPhoneInvalid: false,
      mainProps: {
        width: 75,
        height: 75,
        class: "m1",
      },
      defaultCountry: process.env.VUE_APP_DEFAULT_COUNTRY || "US",
    };
  },
  computed: {
    color: {
      get() {
        return this._.isObject(this.value)
          ? this.value["hex"]
          : this.value
          ? this.value.substring(0, 7)
          : null;
      },
      set(content) {
        const value = this._.isObject(content)
          ? content["hex"]
          : content
          ? content.substring(0, 7)
          : null;
        this.$emit("input", value);
      },
    },
    content: {
      get() {
        return this.value;
      },
      set(content) {
        this.$emit("input", content);
      },
    },
    customLabel() {
      let label = this.label;
      if (
        label &&
        this._.find(
          this.rules,
          (rule) => rule.name === "required" || rule.name === "requiredSelect"
        )
      ) {
        label = `${label} *`;
      }
      return label;
    },
    customPlaceholder() {
      let placeholder = this.placeholder;
      if (
        placeholder &&
        this._.find(
          this.rules,
          (rule) => rule.name === "required" || rule.name === "requiredSelect"
        )
      ) {
        placeholder = `${placeholder} *`;
      }
      return placeholder;
    },
    customSearchKey: {
      get() {
        return this.search;
      },
      set(key) {
        this.search = key;
        this.$emit("search-input", key);
      },
    },
    validationRules: function () {
      const rules = [];
      this.rules.forEach((rule) => {
        switch (rule.name) {
          case "required":
            rules.push(
              (v) =>
                !!v ||
                this.$t("messages.error.required", { entity: this.label })
            );
            break;
          case "requiredSelect":
            rules.push(
              (v) =>
                (v && v.length > 0) ||
                this.$t("messages.error.required", { entity: this.label })
            );
            break;
          case "email":
            rules.push(
              (v) =>
                /.+@.+\..+/.test(v) || this.$t("messages.error.emailFormat")
            );
            break;
          case "max":
            rules.push(
              (v) =>
                (v && v.length <= rule.length) ||
                this.$t("messages.error.maxCharacter", {
                  entity: this.label,
                  length: rule.length,
                })
            );
            break;
          case "min":
            rules.push(
              (v) =>
                (v && v.length >= rule.length) ||
                this.$t("messages.error.minCharacter", {
                  entity: this.label,
                  length: rule.length,
                })
            );
            break;
          case "lessThan":
            rules.push(
              (v) =>
                (v && +v < +rule.value) ||
                this.$t("messages.error.lessThan", {
                  entity: this.label,
                  number: rule.value,
                })
            );
            break;
          case "lessThanOrEqual":
            rules.push(
              (v) =>
                (v && +v <= +rule.value) ||
                this.$t("messages.error.lessThanOrEqual", {
                  entity: this.label,
                  number: rule.value,
                })
            );
            break;
          case "greaterThan":
            rules.push(
              (v) =>
                (v && +v > +rule.value) ||
                this.$t("messages.error.greaterThan", {
                  entity: this.label,
                  number: rule.value,
                })
            );
            break;
          case "greaterThanOrEqual":
            rules.push(
              (v) =>
                (v && +v >= +rule.value) ||
                this.$t("messages.error.greaterThanOrEqual", {
                  entity: this.label,
                  number: rule.value,
                })
            );
            break;
          case "fileSize":
            rules.push(
              (v) =>
                (v && v[0].size < rule.size) ||
                !v ||
                this.$t("messages.error.fileSize", {
                  entity: this.label,
                  size: rule.size / 1000000,
                })
            );
            break;
          case "matchPassword":
            rules.push(
              (v) =>
                v === rule.matchingValue ||
                this.$t("messages.error.unmatchPassword")
            );
            break;
          case "numeric":
            rules.push(
              (v) =>
                (!isNaN(v) && v >= 0) ||
                this.$t("messages.error.numeric", { entity: this.label })
            );
            break;
          case "wholeNumber":
            rules.push((v) => {
              const num = Number(v);
              return (
                (Number.isInteger(num) && num >= 0) ||
                this.$t("messages.error.wholeNumber", { entity: this.label })
              );
            });
            break;
          default:
            break;
        }
      });
      return rules;
    },
  },
  props: {
    useAllowedDate: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    value: [String, Number, Object, Array, File, Date, Boolean],
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    dark: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "",
    },
    classes: {
      type: String,
      default: "col-md-12",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    valueConsistsOf: {
      type: String,
      // default: treeSelectOptions.LEAF_PRIORITY
    },
    accept: {
      type: String,
    },
    options: {
      type: Array,
    },
    stacked: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: [String, Date],
      default: "",
    },
    maxDate: {
      type: [String, Date],
      default: "",
    },
    minTime: {
      type: String,
    },
    maxTime: {
      type: String,
    },
    currentLocale: {
      type: String,
      default: "en-US",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      default: "",
    },
    onFileChange: {
      type: Function,
      default: () => {},
    },
    autocompleteAction: {
      type: Function,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    avatarClass: {
      type: String,
      default: "",
    },
    showImage: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: "",
    },
    itemText: {
      type: String,
      default: "",
    },
    itemValue: {
      type: String,
      default: "",
    },
    showSize: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    trackable: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "hexa",
    },
    showSwatches: {
      type: Boolean,
      default: true,
    },
    hideInputs: {
      type: Boolean,
      default: false,
    },
    hideCanvas: {
      type: Boolean,
      default: false,
    },
    hideModeSwitch: {
      type: Boolean,
      default: true,
    },
    dotSize: {
      type: Number,
      default: 25,
    },
    swatchesMaxHeight: {
      type: Number,
      default: 230,
    },
    pastFile: {
      type: String,
    },
    phoneMode: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      required: false,
    },
    flat: {
      type: Boolean,
      required: false,
    },
    maxNumber: {
      type: Number,
      default: 100,
    },
    suffix: {
      type: String,
      required: false,
    },
    serverErrors: {
      type: Array,
      required: false,
    },
  },
  watch: {
    search(val) {
      this.autocompleteAction(val);
    },
    yearMenu(val) {
      val && setTimeout(() => (this.$refs.yearPicker.activePicker = "YEAR"));
    },
    value(val) {
      this.setDates(val);
      this.setRangeDates(val);
      this.setTime(val);
    },
    dates(val) {
      this.handleInput(val);
    },
    timePickerMenu(val) {
      if (!val) {
        this.saveTime(this.time);
      }
    },
    time(val) {
      this.timeFormatted = val ? moment(val, "HH:mm").format("hh:mm A") : "";
    },
  },
  methods: {
    allowedDates(date) {
      const day = new Date(date).getUTCDay(); // Use UTC day to avoid time zone issues
      return day !== 0 && day !== 6; // Disallow Sunday (0) and Saturday (6)
    },

    handleInput(data) {
      this.$emit("input", data);
    },
    saveYearDate(date) {
      this.date = date;
      this.$refs.yearMenu.save(date);
      this.handleInput(date);
    },
    saveDate(date) {
      this.$emit("clear-server-errors");
      this.datePickerMenu = false;
      this.handleInput(date);
    },
    clearDate() {
      this.saveDate("");
      this.$emit("date-changed");
    },
    handeRangeDates() {
      this.datePickerMenu = false;
      this.dates = this._.sortBy(this.dates);
    },
    setDates(val) {
      this.date = val;
      if (this.type === "yearDatePicker") {
        this.yearPicker = val || "1990-01-01";
      } else if (this.type === "datepicker") {
        this.date = val;
      }
    },
    setRangeDates(val) {
      if (this.type === "rangeDatepicker") {
        this.dates = val || [];
      }
    },
    setTime(val) {
      if (this.type === "timepicker") {
        this.time = val;
      }
    },
    saveTime(time) {
      if (this.timePickerMenu) {
        this.timePickerMenu = false;
      }
      this.$refs.timepicker.selectingHour = true;
      this.handleInput(time);
      this.$emit("time-changed");
    },
    validatePhone(data) {
      this.isPhoneInvalid = this._.isEmpty(data.number.input)
        ? false
        : !data.isValid;
    },
    chooseFile() {
      this.$refs.fileInput.$refs.input.click();
    },
    allowedStepTime() {
      return true;
    },
  },
  mounted() {
    this.setDates(this.value);
    this.setRangeDates(this.value);
    this.setTime(this.value);
  },
};
</script>

<style lang="scss">
.vue-treeselect__control {
  background: var(--v-background);
  border: 0;
}
.vue-treeselect__multi-value-item {
  background: var(--v-background);
  color: var(--v-text);
  border: 1px solid var(--v-text);
  border-radius: 5px;
}
.vue-treeselect__value-remove {
  color: var(--v-text);
  border-left: 1px solid var(--v-text);
}
.v-color-picker {
  margin: auto;
}
.file-label {
  margin-top: -1rem;
}
.v-select.v-input--dense .v-chip {
  margin: 2px !important;
}
.full-width {
  max-width: 100% !important;
}
</style>
