import {List} from 'models/list';
import {Holiday} from 'models/vacation';

const state = {
  holidays: new List()
};

const getters = {
  holidays: (state) => state.holidays
};

const actions = {
  async createHoliday({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.post('/vacations', data);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.created', {entity: this.$app.$tc('models.holiday.entity', 1)})); 
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  },
  async getHolidays({commit}) {
    commit('setLoading', true);
    const response = await this.$app.$http.get('/vacations');
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setHolidays', response.data);
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  }
};

const mutations = {
  setHolidays(state, data) {
    state.holidays = new List({items: data.items.map((holiday) => new Holiday(holiday)), count: data.total_count});
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};