export class ActionAgainst {
  /**
   * @param {Object} actionAgainst FastJson object.
   */
  constructor(actionAgainst = {}) {
    this.id = actionAgainst.id || null;
    this.RES_ID = actionAgainst.attributes.RES_ID || null;
    this.employee_name = actionAgainst.attributes.employee_name || null;
    this.auditor_name = actionAgainst.attributes.auditor_name || null;
    this.approver_name = actionAgainst.attributes.approver_name || "Not approved yet";
    this.actionpolicy_action_name = actionAgainst.attributes.actionpolicy_action_name || null;
    this.days_deduction = actionAgainst.attributes.days_deduction || "No days deduction";
    this.monetary_deduction = actionAgainst.attributes.monetary_deduction || "No money deduction";
    this.percentage_deduction = actionAgainst.attributes.percentage_deduction || "No percentage deduction";
    this.action_ID = actionAgainst.attributes.action_ID || null;
    this.date = actionAgainst.attributes.date || null;
    this.auditor_ID = actionAgainst.attributes.auditor_ID || null;
    this.status = actionAgainst.attributes.status  || false;
    if ( this.status == 0){
      this.status_name = "Pending";
    } else if ( this.status == 1){
      this.status_name = "Approved";
    } else if ( this.status == 2){
      this.status_name = "Waived";
    }
    this.approved_by_ID = actionAgainst.attributes.approved_by_ID || "Not approved yet";
    this.details = actionAgainst.attributes.details || null;
    if( actionAgainst.attributes.action_type == 1){
      this.action_type = "Monetary Deduction";
      this.deduction_amount = actionAgainst.attributes.monetary_deduction;
    } else if ( actionAgainst.attributes.action_type == 2){
      this.action_type = "Days Deduction";
      this.deduction_amount = actionAgainst.attributes.days_deduction;
    } else if ( actionAgainst.attributes.action_type == 3){
      this.action_type = "Percentage Deduction";
      this.deduction_amount = actionAgainst.attributes.percentage_deduction;
    }
  }
}
